import {
  CURRENCYSYMBOLSMAP,
  dateRangetoDays,
  formatUserPlatform,
  parseFilters,
} from "./Utilities";
export const getFormatedContentForHistories = async ({ historyData }) => {
  let resultContent = "";
  // Formate values
  const formatValue = (value) => {
    // Coverting string becuase sometimes values coming in number and formated
    const str = String(value);
    return str?.includes(",")
      ? str
      : Number(str)?.toLocaleString("en-US", {
          minimumFractionDigits: Number(str) % 1 === 0 ? 0 : 2,
          maximumFractionDigits: 2,
        });
  };
  // Condition for NaN check
  const formatWithFallback = (value, fallback = "0") =>
    formatValue(value) === "NaN" ? fallback : formatValue(value);
  // Fomate percentage values
  const parsePercentage = (percentage) => {
    const numericValue = percentage?.replace("%", "").trim();
    return isNaN(numericValue) ? "0" : formatValue(numericValue);
  };
  // Formate audience name for variation
  const formatVariation = (variation) =>
    variation
      .replace(/_/g, " ")
      .replace(/(?:^|\s)(\w)/g, (match, p1) => p1.toUpperCase())
      .replace("Variation", "Variant ");

  // Filter all test history details
  const variations = [];
  historyData?.forEach((history) => {
    const filters = parseFilters(history?.filters);
    // Filter only necessary data with variations
    history?.report?.calculatedResults?.forEach((variant) => {
      const refetchData = history?.report;
      variations?.push({
        user: filters,
        testName: history?.testName,
        currency: history?.currency,
        results: refetchData?.result,
        livedate: history?.livedate,
        resultsEvaluation: history?.report?.resultsEvaluation?.value,
        totalResults: refetchData?.totalOfResults,
        ...variant,
      });
    });
  });
  const processDataByTest = (variations) => {
    const groupedByTest = variations?.reduce((acc, item) => {
      const testName = item?.testName || "Unknown Test";
      if (!acc[testName]) acc[testName] = [];
      acc[testName]?.push(item);
      return acc;
    }, {});

    const result = [];
    for (const [_, testVariations] of Object.entries(groupedByTest)) {
      const groupedByAudience = testVariations?.reduce((acc, item) => {
        const audienceName = item?.audienceName;
        if (!acc[audienceName]) acc[audienceName] = [];
        acc[audienceName]?.push(item);
        return acc;
      }, {});

      const priorityUsers = [
        "users",
        "mobile",
        "new visitor",
        "returning visitor",
        "desktop",
      ];

      for (const audienceVariations of Object.values(groupedByAudience)) {
        let usersData = null;
        let positiveDataMap = {};

        for (const item of audienceVariations) {
          const hasPositiveChange =
            parseFloat(item?.changeInConversionRate?.replace(/%/g, "").trim()) >
            0;

          if (!usersData && item?.user === "users") {
            usersData = item;
          }

          if (hasPositiveChange) {
            positiveDataMap[item?.user] = item;
          }
        }

        let positiveData = null;
        for (const priority of priorityUsers) {
          for (const key in positiveDataMap) {
            if (key.includes(priority)) {
              positiveData = positiveDataMap[key];
              break;
            }
          }
          if (positiveData) break;
        }

        result.push(positiveData || usersData);
      }
    }
    return result;
  };

  const result = processDataByTest(variations);
  // Message generation
  const combinedTestMessages = await Promise.all(
    result?.map(async (data) => {
      const totalTransactions = data?.totalResults?.totalTransactions || 0;
      const daysCount = dateRangetoDays(data?.livedate);
      const platforms = data?.user;
      const testName = data?.testName;
      const currency = CURRENCYSYMBOLSMAP[data?.currency];
      let messages = "";
      if (!data) {
        messages += `>Test has been running for *${daysCount}* days, you will receive an update for this next week.\n\n`;
      } else {
        const controlDataLast = data?.results?.find(
          (ref) =>
            ref.controlvariant === "Reference" ||
            ref.controlvariant === "reference"
        );

        const vDataLast = data?.results?.find(
          (ref) => ref.controlvariant === data?.audienceName
        );

        const {
          changeInConversionRate,
          additionalTransactionsPerMonth,
          additionalRevenuePerYear,
        } = data;

        const isCRPositive =
          parseFloat(changeInConversionRate?.replace(/%/g, "").trim()) > 0;

        const rpuChange =
          Number(vDataLast?.calculatedAverageRevenuePerUser) <
          Number(controlDataLast?.calculatedAverageRevenuePerUser)
            ? "Decrease"
            : "Increase";
        const aovChange =
          Number(vDataLast?.calculatedAveragePurchaseRevenue) <
          Number(controlDataLast?.calculatedAveragePurchaseRevenue)
            ? "Decrease"
            : "Increase";

        let variantMessage = `*${testName}*\n`;
        variantMessage += `>💡\n`;
        variantMessage += `>The ${
          !isCRPositive ? "Control" : `${formatVariation(data.audienceName)}`
        } is performing better on: *${formatUserPlatform(platforms)}*\n`;

        variantMessage += `>RPU: ${rpuChange} from *${currency}${formatValue(
          controlDataLast?.calculatedAverageRevenuePerUser
        )}* to *${currency}${formatValue(
          vDataLast?.calculatedAverageRevenuePerUser
        )}*\n`;
        variantMessage += `>Sample size: *${formatValue(
          totalTransactions
        )}* transactions\n`;

        variantMessage += `>AOV: ${aovChange} from *${currency}${formatWithFallback(
          controlDataLast?.calculatedAveragePurchaseRevenue
        )}* to *${currency}${formatWithFallback(
          vDataLast?.calculatedAveragePurchaseRevenue
        )}*\n`;

        variantMessage += `>Change in CR: *${
          isCRPositive ? "+" : ""
        }${parsePercentage(changeInConversionRate)}%*\n`;

        if (additionalTransactionsPerMonth > 0) {
          variantMessage += `>💲\n`;
          variantMessage += `>Additional transactions per month, at current traffic: *+${formatValue(
            additionalTransactionsPerMonth
          )}* transactions\n`;
          variantMessage += `>Additional revenue per year, at current AOV: *${currency}${formatValue(
            additionalRevenuePerYear
          )}*\n`;
        }

        variantMessage += `>⏳\n`;
        variantMessage += `>The test has been running for: *${daysCount}* days\n`;
        variantMessage += `>The test can be evaluated in: *${
          data?.resultsEvaluation?.split(" ")[0]
        }* days\n`;
        messages += variantMessage;
      }
      return messages;
    })
  );

  resultContent += combinedTestMessages?.join("\n\n");
  return resultContent;
};
