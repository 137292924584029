import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { VERIFYTWOFACTOR } from "../../Graphql/Mutations";
import { MYPROFILE, TWOFACTORQRCODE } from "../../Graphql/Queries";
import { logOut } from "../../Libs/Utilities";
import MyLoader from "../MiroComponents/PreLoader";

export function TwoFactorAuthentication() {
  const { loading, data, error } = useQuery(TWOFACTORQRCODE);
  const [verifyCode] = useMutation(VERIFYTWOFACTOR);
  const navigate = useNavigate();
  useEffect(() => {
    if (!loading && !data?.myProfile) {
      logOut();
    }
    return () => {};
  }, [loading, data]);

  useEffect(() => {
    document.querySelectorAll(".digit-group input").forEach(function (el) {
      el.setAttribute("maxlength", 1);
      el.addEventListener("keyup", function (e) {
        var parent = el.parentNode;
        if (e.keyCode === 8 || e.keyCode === 37) {
          const prev = parent.querySelector(
            "input#" + el.getAttribute("data-previous")
          );
          if (prev) {
            prev.focus();
          }
        } else if (
          (e.keyCode >= 48 && e.keyCode <= 57) ||
          (e.keyCode >= 65 && e.keyCode <= 90) ||
          (e.keyCode >= 96 && e.keyCode <= 105) ||
          e.keyCode === 39
        ) {
          el.value = e.key;
          var next = parent.querySelector(
            "input#" + el.getAttribute("data-next")
          );
          if (next) {
            next.focus();
          } else {
            if (parent.getAttribute("data-autosubmit")) {
              parent.submit();
            }
          }
        }
      });
    });
  }, [loading]);

  const promtError = (message) => {
    var errorDiv = document.querySelector("#error-messages");
    errorDiv.innerHTML = message;
    errorDiv.style.opacity = 1;
    // setTimeout(function () {
    //   errorDiv.style.opacity = 0;
    // }, 3000);
  };

  if (loading) return <MyLoader />;
  if (error) return <div> {error.message}</div>;
  // console.log(data);
  return (
    <div>
      <div className="login-wrapper">
        <div className="login-card text-center two-factor-form">
          <div className="login-header">
            <div className="logo-wrapper">
              <img
                style={{ width: "90px" }}
                src={`${window.location.origin}/Images/AcceleratedLOGOICON.png`}
                alt=""
              />
            </div>
          </div>

          {!data?.generateQRCode?.enabled && (
            <>
              <img
                className="mb-3 w-100"
                style={{ maxWidth: "145px", borderRadius: "5px" }}
                src={data?.generateQRCode?.qrString}
              />
            </>
          )}
          <h4 className="">
            <strong>
              {!data?.generateQRCode?.enabled
                ? `Configure Two Factor Authentication to Secure Your Account.`
                : `We Have Sent You a Auth Code to Verify Your Account.`}
            </strong>
          </h4>
          <h4> Enter your Auth code here</h4>
          <form
            onSubmit={async (_) => {
              _.preventDefault();
              const codeSelectors =
                document.querySelectorAll(".digit-group input");
              let code = [];
              for (const val of codeSelectors) {
                code.push(val.value);
              }
              if (code.every((val) => val !== "")) {
                const authcode = code.join("");
                try {
                  const { data } = await verifyCode({
                    variables: {
                      authcode,
                    },
                  });
                  document.cookie = `codebase_token=${
                    data?.verifyTwoFactor?.token
                  }; path=/; max-age=${
                    20 * 24 * 60 * 60
                  }; secure; SameSite=Lax`;
                  window.location.href = `${window.location.origin}/authorized`;
                } catch (error) {
                  // toast.error(error?.message);
                  promtError(error?.message);
                }
              } else {
                promtError("Incorrect Auth Code.");
              }
            }}
          >
            <div className="digit-group">
              <input
                type="text"
                id="digit-1"
                name="digit-1"
                data-next="digit-2"
              />
              <input
                type="text"
                id="digit-2"
                name="digit-2"
                data-next="digit-3"
                data-previous="digit-1"
              />
              <input
                type="text"
                id="digit-3"
                name="digit-3"
                data-next="digit-4"
                data-previous="digit-2"
              />
              <input
                type="text"
                id="digit-4"
                name="digit-4"
                data-next="digit-5"
                data-previous="digit-3"
              />
              <input
                type="text"
                id="digit-5"
                name="digit-5"
                data-next="digit-6"
                data-previous="digit-4"
              />
              <input
                type="text"
                id="digit-6"
                name="digit-6"
                data-previous="digit-5"
              />
            </div>
            <div className="fields-wrapper">
              <button type="submit"> Continue</button>
            </div>
            <span
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={(e) => {
                logOut();
              }}
            >
              {" "}
              Cancel and return to login.
            </span>
          </form>
          <div className="error-message-wrapper">
            <button type="button" id="error-messages">
              {" "}
              Error message space
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
